.sectionBookNow {
  margin-top: -50px;
  padding-top: 50px;
  margin-bottom: 30px;
  padding-bottom: 40px;
}
.sectionInfoContact{
  &::before{
    background-color: var(--primary);
    height: 100%;
    width: 100%;
    position: absolute;
    content: "";
    z-index: -1;
  }
}

.bgContact{
  position: relative;
}
.bgContact::before{
  background: url(../../public/images/bg-contact.png);
  background-repeat: no-repeat;
  position: absolute;
  content: "";
  top: -227px;
  left: 0;
  width: 100%;
  height: 170%;
  background-size: cover;
  @media(max-width:767px){
    height: 133%;
  }
}