.mainFooter {
  padding: 70px 0;
  .titleMenuFooter{
    font-size: 20px;
  }
  .menuFooter{
    li{
      a{
        color: rgba(255, 255, 255 , .75);
        display: inline-block;
        padding: 7px 0;
        font-size: 18px;
      }
    }
  }
  .dividerFooter{
    margin-top: 45px;
    padding-top: 30px;
    border-top: 3px solid rgba(70, 32, 9 ,.2);
  }
  .formSubscribe{
    background: rgba(255, 255, 255 , .1);
    :global{
      .ant-form{
        border-radius: 7px ;
        overflow: hidden;
      }
      .ant-form-item{
        margin-bottom: 0 !important;
      }
    }
    .form{
      display: flex;
      .formItem{
        flex: 1 0 0%;
        input{
          // border-top-left-radius: 0;
          // border-top-right-radius: 0;
          border-radius: 0;
        }
      }
      .submitButton{
        flex: 0 0 auto;
        border-radius: 0;
        min-height: 53px;
        background-color: var(--secondary);
        width: 50px;
        padding: 0;
        gap: 0;
      }
    }
  }
}



.formSubscribe{
  :global{
    .ant-form-item .ant-form-item-explain-error {
      color: #fff;
    }
  }
}