.thumbnailSlider{
  :global{
    .slick-slide > div{
      margin: 0px 4px;
      img{
        height: 45px;
        object-fit: cover;
      }
    }
  }
}




.cardAccommodation{
  box-shadow: 3.35223px 8.38057px 3.35223px rgba(0, 0, 0, 0.25);
  background-color: #FAF6F3;
  &.selected{
    outline: 2px solid #000;
    // &::before{
    //   border: 3px solid #462009;
    //   width: 100%;
    //   height: 100%;
    //   top: 0;
    //   left: 0;
    //   position: absolute;
    //   content: "";
    //   z-index: 1;
    //   border-radius: 8px;
    // }
  }
  :global{
    .ant-card-body{
      padding: 0;
    }
    @media(max-width:1023px){
      .ant-image{
        width: 100% !important;
      }
    }
  }

}

