// .topInfo{
//  background-color:  var(--primary);;
// }

.mainHeader {
    z-index: 1;
    &::before {
      position: absolute;
      content: "";
      background: rgba(228, 228, 228, 0.6);
      opacity: 0.9;
      filter: blur(14.5px);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .menu{
      li{
        a{
          color: #000;
          padding: 5px;
          margin: 0px 10px;
          font-size: 20px;
        }
      }
    }
  }
  
  .infoContact{
      li {
          &:not(:last-of-type){
              margin-inline-end: 25px;
          }
          a{
        color: #FFF;
              svg{
                  width: 18px;
              }
          }
      }
  }
  
  .socialMedia {
    li {
          &:not(:last-of-type){
              margin-inline-end: 15px;
          }
      a {
        color: #FFF;
        svg {
          width: 20px;
        }
      }
    }
  }
  