
.widget_itemTour {
  border-radius: 25px;
  background-color: #faf6f3;
  overflow: hidden;
  filter: drop-shadow(4px 10px 4px rgba(181, 113, 52, 0.25));

  .widget_itemImage {
    :global{
      .ant-image{
        display: block;
      }
    }
    img {
      width: 100%;
    }
  }
  .widget_itemText {
    color: #78665c;
  }
}

.cardAccommodation{
  box-shadow: 3.35223px 8.38057px 3.35223px rgba(0, 0, 0, 0.25);
  background-color: #FAF6F3;
  &.selected{
    &::before{
      border: 3px solid #462009;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      content: "";
      z-index: 1;
      border-radius: 8px;
    }
  }
  :global{
    .ant-card-body{
      padding: 0;
    }
    @media(max-width:1023px){
      .ant-image{
        width: 100% !important;
      }
    }
  }

}




.boxChecbox{
  background-color: #FAF6F3;
  :global{
    .ant-checkbox-inner{
      border: 1px solid var(--primary);
      background-color: transparent;
    }
  }
}


:global{
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked{
    background-color: var(--primary);
    color: #FFF !important;
  }
}

.filterAccommodation{
  :global{
    .ant-radio-button-wrapper{
      &::before{
        content: unset;
      }
      margin-inline-end: 25px;
      border-radius: 30px;
      color: var(--primary);
      border: 2px solid var(--primary);
      height: 40px;
      line-height: 40px;
      padding-inline: 17px;
    }
  }
}

.groupCheckbox{
  :global{
    .ant-checkbox .ant-checkbox-inner {
      width: 22px;
      height: 22px;
      .ant-checkbox+span {
        padding-inline-start: 14px;
      }
      &::after{
        top: 48%;
        inset-inline-start: 27%;
        width: 6.714286px;
        height: 12.142857px;
        border: 3px solid #fff;
        border-top: 0;
        border-inline-start: 0;
      }
      }
    }
  }
  .thumbnailSlider{
    :global{
      .slick-slide > div{
        margin: 0px 4px;
        img{
          height: 45px;
          object-fit: cover;
        }
      }
    }
  }

  .stepBooking{
    :global{
      
      .ant-steps-item{
        overflow: visible;
        &::after{
          position: absolute;
          top: 15px;
          inset-inline-start: 0;
          display: block;
          width: 100%;
          height: 8px;
          background-color: var(--primary);
          content: "";
          z-index: -2;
          transform: unset !important;
          @media(max-width:575px){
            width: 4px;
            height: 100%;
            inset-inline-start: 18px;
          }
        }
        @media(min-width:575px){
          &:last-of-type{
            &::after{
              width: 30%;
            }
          }
        }
        @media(max-width:575px){
          &:last-of-type{
            &::after{
              height: 30%;
            }
          }
        }
        &.ant-steps-item-finish{
          &::after{
            background-color: #462009;
          }
          .ant-steps-item-container{
            .ant-steps-item-icon{
              background-color: #462009;
              border-color: #462009;
            }
          }
        }
        &.ant-steps-item-process{

          @media(min-width:575px){
            &::before{
              position: absolute;
              top: 15px;
              inset-inline-start: 0;
              display: block;
              width:28%;
              height: 8px;
              background-color:#462009;
              content: "";
              z-index: -1;
          }
          }
          .ant-steps-item-container{
            @media(min-width:575px){
              &::before{
                position: absolute;
                content: url(../../public/images/camal2.svg);
                inset-inline-start: -47px;
                top: -30px;
                transform: rotate(-3deg);
              }
            }
            .ant-steps-item-icon{
              background-color: #462009;
              border-color: #462009;
            }
          }
        }
        .ant-steps-item-container{
          .ant-steps-item-icon{
            background-color: var(--primary);
            width: 42px;
            height: 42px;
            display: flex ;
            align-items: center;
            justify-content: center;
            .ant-steps-icon{
              color: #FFF;
              font-size: 20px;
              font-weight: bold;
            }
          }
          .ant-steps-item-content{
            display: block;
            .ant-steps-item-title{
              font-weight: bold;
              &::after{
                content: unset !important;
              }
            }
          }
        }
      }
      @media(min-width:575px){
        .ant-steps-item:first-child {
          padding-inline-start: 70px !important;
          .ant-steps-item-container{
            &::before{
              inset-inline-start: 0px !important;
            }
          }
        }
      }
    }
  }


  :global{
    .ant-picker-dropdown .ant-picker-cell-disabled {
      color: #fff;
      &::before{
        background: #B4B1B3;
      }
  }
  .ant-picker-dropdown .ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: transparent;
  }
  }
