.widget_itemBlog{
    margin-bottom: 30px;
    overflow: hidden;
    .widget_itemTitle{
      height: 60px;
      overflow: hidden;
        a:hover{
            color: var(--primary);
        }
    }
    .widget_itemText{
        height: 64px;
        overflow: hidden;
    }
    .widget_itemCategory{
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(3.98006px);
      inset-inline-end: 10px;
    }
    .widget_itemContent{
      border: 3px solid #64412C;
      border-top: 0;
    }
}

.blogSingleImage{
    width: 100%;
}

.blogSingleContent{
  :global{
    .ant-image{
      display: block;
    }
  }
}