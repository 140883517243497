

.widget_itemTestimonial {
  padding: 20px;
  box-shadow: 0px 28px 60px rgba(21, 21, 21, 0.15);
  border-radius: 16px;
  // margin: 40px 0 70px;
  position: relative;
  &.center{
    transform: translateY(-25px);
    border: 1px solid var(--primary);
  }
  .widget_itemAvatar {
    position: absolute;
    top: -35px;
    inset-inline-start: 20px;
  }
  .widget_itemText{
    height: 120px;
    overflow: hidden;
  }
  .widget_itemIconLike{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}