.hero {
  @media(min-width:1024px){
    height: 85vh;
  }
  @media(max-width:1024px){
    height: 55vh;
  }
  .heroContent {
    background: rgba(255, 255, 255, 0.55);
    border-radius: 20px 20px 0px 0px;
  }
  .heroBtn {
    background-color: #462009;
    color: #fff;
    border: 0;
    height: 60px;
    padding: 5px 50px !important;
  }
  :global{
    .react-loading-skeleton{
      position: absolute;
      top: 0;
      height: 100%;
    }
  }
}

.bgHero{
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;z-index: -1;
  div{
    height: 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover ;
    }
  }
  :global{
    .ant-image-img{
      height: 100%;
    }
  }
}


.ripple {
  position: relative;
  z-index: 1;
  &::before,
  &::after {
    opacity: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 5px;
    left: 5px;
    content: "";
    height: 90%;
    width: 90%;
    border: 5px solid #b570348e;
    border-radius: 100%;
    z-index: -1;
    -webkit-animation-name: ripple;
    animation-name: ripple;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  }
}

.ripple::before {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.ripple::after {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.95);
  }
  to {
    opacity: 0;
    transform: scale3d(1.75, 1.75, 1.1);
  }
}



.contentStep {
  box-shadow: 0px 0px 30px rgba(0, 44, 122, 0.13);
  border-radius: 8px;
}

.contentStepImage {
  background-color: #faf6f3;
}

.widget_itemBrand {
  .widget_itemImage {
    img{
      border-radius: 50%;
      object-fit: cover;
      border: 4px solid var(--primary);
    }
  }
}

.contentStepList{
  li{
    margin-bottom: 30px;
    font-size: 20px;
    svg{
      font-size: 14px;
    }
  }
}





 .prev-pageination:disabled,
 .next-pageination:disabled {
  pointer-events: none;
  opacity: .5;
}