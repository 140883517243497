.pageTitle{
  margin-bottom: -35px;
  min-height: 73px;
  margin-bottom: 50px;
  margin-bottom: -41px;
  position: relative;
  z-index: 2;
  &::before{
    position: absolute;
    z-index: -1;
    content: "";
    width: 100%;
    height: 5px;
    top: 48%;
    transform: translateY(-50%);
    left: 0;
    background-color: var(--primary);
  }
  h2{
    min-height: 60px;

  }
}


.headerContact{
  padding-top: 90px;
   padding-bottom: 129px;
   &::before{
    background-color: #FFF;
   }
}