// .topInfo{
//  background-color:  var(--primary);;
// }

.top {
  height: 70px;
}
@keyframes fadeInDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.mainHeader {
  z-index: 10;
  position: sticky;
  top: 0;
  transition: all 0.3s ease;
  // transition: all .2s ease-in-out;
  &::before {
    position: absolute;
    content: "";
    background-color: rgba(228, 228, 228, 0.8);
    opacity: 1;
    filter: blur(14.5px);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  &.internal {
    position: fixed;
    width: 100%;
    right: 0;
    top: 48px;
    z-index: 2;
  }
  &.scrolled {
    animation: fadeInDown 0.5s ease; // ربط الأنيميشن
    top: 0;
  }
  .menu {
    li {
      a {
        color: #000;
        padding: 5px;
        margin: 0px 8px;
        font-size: 20px;
        position: relative;
        @media (max-width: 1169px) {
          margin: 0px 5px;
          font-size: 16px;
        }
      }

      :global {
        .active {
          @media (min-width: 992px) {
            color: #462009;
            &::before {
              position: absolute;
              content: "";
              width: 100%;
              height: 6px;
              background-color: #462009;
              right: 0;
              bottom: -15px;
            }
          }
          @media (max-width: 992px) {
            color: #462009;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.infoContact {
  li {
    &:not(:last-of-type) {
      margin-inline-end: 25px;
    }
    a {
      color: #fff;
      svg {
        width: 18px;
      }
    }
  }
}

.socialMedia {
  li {
    &:not(:last-of-type) {
      margin-inline-end: 15px;
    }
    a {
      color: #fff;
      svg {
        width: 20px;
      }
    }
  }
}

@media (max-width: 991px) {
  .logo {
    :global {
      .ant-image,
      img {
        height: 50px !important;
      }
    }
  }
}
