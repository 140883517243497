.nabtFaq {
  background-color: #FFF;
  border: 0px solid #d9d9d9;
  :global{
    .ant-collapse-item{
      padding: 40px 0 50px;
      .ant-collapse-header{
        padding-inline-start: 0;
        .ant-collapse-expand-icon{
          order: 1;
        }
        .ant-collapse-header-text{
          font-size: 20px;
          font-weight: bold;
          color: #462009;
        }
      }
      .ant-collapse-content-box{
        color: #6F6C90;
        padding-inline-start: 0;
        font-size: 20px;
      }
      .ant-collapse-content{
        border: 0;
      }
    }
  }
}

.swiperCategoty{
  :global{
    .swiper-slide{
      border: 1px solid #D9DBE9;    
      color: #757A81;
      font-size: 20px;
      font-weight: bold;  
      border-radius: 14px;
      padding: 18px 40px;
      cursor: pointer;
    }
    .swiper-slide-active{
      background-color: transparent;
      color: #757A81;
    }
    .active{
      background-color: var(--primary);
      color: #FFF;
    }
  }
}