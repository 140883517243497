.widget_itemEvent {
  &.booking{
    border: 3px solid #959494;
    padding: 8px 10px;
    border-radius: 10px;
    cursor: pointer;
    &.selected{
      border-color: #462009;
    }
  }
  .widget_itemContent{
    border: 4px solid var(--primary);
    padding: 60px 20px 20px;
    border-radius: 15px;
    margin-top: -55px;
    box-shadow: 0px 3.89474px 10.5158px rgba(81, 37, 0, 0.19)
  }
  .widget_itemCheck{
    z-index: 2;
    inset-inline-end: 15px;
    border: 2px solid transparent;
     &.checked{
       border-color: #64412C;
     }
   }
  :global{
    .ant-image{
      margin: auto;
    }
  }
  img{
    border-radius: 50%;
    object-fit: cover;
  }
  .widget_itemText{
    height: 85px;
    overflow: hidden;
  }
}


.thumbnailSlider{
  :global{
    .slick-slide > div{
      margin: 0px 4px;
      img{
        height: 45px;
        object-fit: cover;
      }
    }
  }
}