.sectionBboutBg {
  z-index: 1;

  &::before {

    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: url(../../public/images/bg-about.png);
    top: 0;
    left: 0;
    z-index: -1;
  }
}


.text-about {
  color: #64412C;
  font-size: 20px;
}

.contenAbout {
  background-color: #FAF6F3;
  border-radius: 15px;
  padding: 25px;
  height: 100%;
}

.imageAbout {
  border-radius: 15px !important;
}

:global{
  .ant-image{
    display: block;
  }
}


@media(max-width:991px){
  .row-form {
    margin-bottom: 0px;
  }
}