.menuDash{
  border: 0 !important;
  :global{
    .ant-menu-item , .ant-menu-submenu{
      position: relative;
      .ant-menu-submenu-arrow{
        display: none;
      }
      margin: 0;
      margin-bottom: 25px;
      &::before{
        position: absolute;
        content: "";
        // width: ;
      }
      .ant-menu-item ,.ant-menu-submenu-title{
        // border-right: 3px solid #757A81 !important;
        border-radius: 0 !important;
        &:not(.ant-menu-submenu-inline){
          border-inline-start: 3px solid #757A81 !important;
        }
        // &::before{
        //   position: absolute;
        //   content: "";
        //   width: 3px;
        //   background-color: #757A81;
        //   inset-inline-start: 0;
        //   height: 100%;
        //   top: 0;
        // }
        border-radius: 5px 0 0px 5px;
      }
      .ant-menu{
        background-color: transparent !important;
      }
      .ant-menu-sub.ant-menu-inline{
        padding-inline-start: 20px;
      }
      .ant-menu-sub .ant-menu-item-group-title{
        position: relative;
        &::before{
          position: absolute;
          content: "";
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #757A81;
          inset-inline-start: 15px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .ant-menu-item-group{
        a.active{
          color: var(--primary);
        }
      }
      &.ant-menu-item-selected{
        background-color: var(--primary);
        color: #FFF;
        .ant-menu-item-icon{
          color: #FFF;
          &::before{
            color: #FFF;
          }
        }
      }
    }
  }
}

.wedget_itemInfo{
background: linear-gradient(180deg, #FEDDC5 0%, #B57134 80%);
border-radius: 15px;
.wedget_itemIcon{
  img{
    max-height: 20px;
  }
}
}

:global{
  .ant-table-container{
    box-shadow: 0px 3px 49px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }
}

.uploadNbt{
  :global{
    .ant-upload-list.ant-upload-list-picture-card{
      justify-content: flex-end;
      border: 1px solid #d9d9d9;
      padding: 8px;
      border-radius: 8px;
      align-items: center;
    }
    .ant-upload {
      margin-right: auto;
      height: auto !important;
      background-color: transparent !important;
      border-color:transparent !important ;
      .ant-btn{
        background-color: #FAF6F3 !important;
        border-color:#D4A373 !important ;
        color: #D4A373;
      }
    }
  }
}