.widget_itemDestnation {
  background-color: #faf6f3;
  overflow: hidden;
  border-radius: 15px;
box-shadow:4px 10px 4px rgba(181, 113, 52, 0.25);
// &.booking{
//   &::before{
//     position: absolute;
//     content: "";
//     width: 100%;
//     height: 100%;
//     border: 4px solid transparent;
//     top: 0;
//     left: 0;
//     z-index: 1;
//     border-radius: 15px;
//   }
// }
  &.selected{
    // transition: none !important;
    outline: 2px solid #000;
    // &::before{
    //     border-color: #462009;
    // }
    :global{

      .btn-primary {
        position: relative;
        z-index: 1;
        // margin: 0px 4px 4px;
        // width: 97.8%;
        // border-radius: 0 0 10px 9px;
        // border: 0;
      }
    }
  }
  .widget_itemCheck{
   z-index: 2;
   inset-inline-end: 30px;
   border: 2px solid transparent;
    &.checked{
      border-color: #64412C;
    }
  }
  .widget_itemImage {
    :global{
      .ant-image{
        display: block;
      }
    }
    img {
      width: 100%;
    }
  }
  .widget_itemText {
    color: #78665c;
  }
}

.thumbnailSlider{
  :global{
    .slick-slide > div{
      margin: 0px 4px;
      img{
        height: 45px;
        object-fit: cover;
      }
    }
  }
}

:global{
  .ant-radio-button-wrapper-checked{

    .radio-content{
      img{
        filter: brightness(0) invert(1);
      }
    }
  }
}